
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'AppProgress',

  props: {
    customLineClass: {
      type: [String, Array, Object],
      required: true
    },
    
    value: {
      type: [String, Number],
      required: true
    }
  },

  setup(props) {
    const progressStyle = computed<string>(() => `width:${props.value}%`);

    return { progressStyle };
  }
});
