
  import { defineComponent, PropType, computed, toRefs } from 'vue';

  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppBadge from '@/components/stateless/AppBadge.vue';
  import AppProgress from '@/components/stateless/AppProgress.vue';
  import AppTooltip from '@/components/stateless/AppTooltip.vue';

  import { IGoal, ITaskRes } from '@/types';
  import { router, routesNames } from '@/router';

  export default defineComponent({
    name: 'AppCollapseItem',

    components: { AppIcon, AppBadge, AppProgress, AppTooltip },


    props: {
      goal: {
        type: Object as PropType<IGoal>,
        required: true
      },

      active: {
        type: Boolean,
        required: true
      }
    },

    emits: ['prioritize'],

    setup(props) {
      const { goal } = toRefs(props);

      const completedTasksLength = computed<number>(() => {
        if (goal.value.tasks) {
          return goal.value.tasks.filter((el: ITaskRes) => el.status === 'completed').length;
        }
        return 0;
      });

      function onGoalTitleClick () {
        router.push({ name: routesNames.myGoal , query: { id: props.goal.id }});
      }

      return { completedTasksLength, onGoalTitleClick };
    }
  });
