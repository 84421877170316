
import { defineComponent } from 'vue';

import AppButton from '@/components/stateless/AppButton.vue';
import AppIcon from '@/components/stateless/AppIcon.vue';

import { routesNames, router } from '@/router';

export default defineComponent({
  name: 'GoalsBanner',

  components: { AppButton, AppIcon },

  setup () {
    return { routesNames, router };
  }
});
