
  import { defineComponent, PropType, reactive, toRefs, onMounted, ref, watch, nextTick } from 'vue';
  import { useToast } from "vue-toastification";

  import AppCollapseItem from '@/components/stateless/AppCollapseItem.vue';
  import AppCollapse from '@/components/stateless/AppCollapse.vue';
  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import GoalsBanner from '@/views/home/components/goals/GoalsBanner.vue';
  import TaskRow from '@/components/TaskRow.vue';
  import GoalItem from '@/views/home/components/goals/GoalItem.vue';
  import AppTooltip from '@/components/stateless/AppTooltip.vue';

  import { IGoal, ITaskRes } from '@/types';
  import { myGoalsService } from '@/services';
  import { vuex } from '@/store';

  export default defineComponent({
    name: 'GoalsList',

    components: {
      AppCollapseItem,
      AppCollapse,
      GoalItem,
      AppIcon,
      TaskRow,
      AppButton,
      GoalsBanner,
      AppTooltip
    },

    props: {
      goals: {
        type: Array as PropType<IGoal[]>,
        required: true
      },

      goalsCount: {
        type: Number,
        required: true
      },

      disabledBanner: {
        type: Boolean,
        defautl: false
      },

      disabledTasks: {
        type: Boolean,
        defautl: false
      }
    },

    emits: ['view-more'],

    setup(props) {
      let goalsListContainer = ref(null) as any;
      const toast = useToast();
      const state = reactive({
        openedGoals: [] as string[],
        goalsList: [] as IGoal[]
      });

      const { openedGoals, goalsList } = toRefs(state);

      watch(() => props.goals.length, () => { 
        setLocalGoals();

        nextTick(() => {
          scrollToTop();
        });
      });

      function scrollToTop () {
        goalsListContainer.value.scrollTop = 0;
      }

      function openFirstTask () {
        if (!props.goals.length) return;

        openedGoals.value = goalsList.value[0].tasks?.length ? [goalsList.value[0].id.toString()] : [];
      }

      // Keep goal locally to update goal view without making response for a goal
      function setLocalGoals () { goalsList.value = props.goals; }

      function changeLocalTaskStatus (goalIndex: number, res: ITaskRes) {
        const tasks = goalsList.value[goalIndex].tasks;
        if (!tasks) return;

        goalsList.value[goalIndex].tasks = tasks.map((el: ITaskRes) => el.id === res.id ? res : el);
      }

      async function handleGoalPriority(goal: IGoal) {
        vuex.setLoading(true);

        return (goal.markedWithStarAt 
          ? myGoalsService.removeStarMarkGoal(goal.id) 
          : myGoalsService.markWithStarGoal(goal.id))
          .then((res: IGoal) => { 
            goalsList.value = goalsList.value.map((g: IGoal) => {
              if (g.id === goal.id) {
                g.markedWithStarAt = res.markedWithStarAt;
              }

              return g;
            });
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function taskStatusUpdate (goalIndex: number, { id, status }: ITaskRes) {
        vuex.setLoading(true);

        await myGoalsService.updateTaskStatus(status, id)
          .then((res: ITaskRes) => { changeLocalTaskStatus(goalIndex, res); })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      onMounted(() => {
        setLocalGoals();
        openFirstTask();
      });

      return {
        openedGoals,
        goalsList,
        goalsListContainer,
        handleGoalPriority,

        taskStatusUpdate
      };
    }
  });
