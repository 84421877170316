<template>
  <div v-if="goalsList.length" class="overflow-auto">
    <div class="collapse-wrapper h-full">
      <div 
        ref="goalsListContainer" 
        class="overflow-y-auto overflow-x-hidden pr-6 sm:pr-12 md:pr-14 py-20 h-full max-h-300 md:max-h-full"
      >
        <AppCollapse v-model="openedGoals">
          <AppCollapseItem
            v-for="(goal, index) of goalsList"
            :key="goal.id"
            :disabled="!goal.tasks || !goal.tasks.length"
            :name="goal.id.toString()"
            class="mb-20"
          >
            <!-- H E A D E R -->
            <template #header="{ collapsed }">
              <GoalItem :goal="goal" :active="!collapsed" @prioritize="() => handleGoalPriority(goal)" />
            </template>

            <!-- T R I G G E R  I C O N -->
            <template #triggerIcon="{ collapsed }">
              <div class="flex items-center gap-10 lg:gap-20">
                <AppTooltip :disabled="!!goal.markedWithStarAt" class="star-icon hidden">
                  <AppIcon
                    name="star"
                    size="24"
                    :class="[
                      !goal.markedWithStarAt 
                        ? 'text-posey-blue' 
                        : 'text-liquid-gold',
                      'cursor-pointer'
                    ]"
                    @click.stop="() => handleGoalPriority(goal)"
                  />
                  <template #content>
                    <span class="bg-transparent">Move to top</span>
                  </template>
                </AppTooltip>

                <div
                  class="toggle-icon rounded-5 flex items-center justify-center transform"
                  :class="[
                    collapsed ? 'rotate-0 bg-placebo-blue' : 'rotate-180 bg-primary',
                    !goal.tasks || !goal.tasks.length ? 'opacity-30 cursor-not-allowed' : 'cursor-pointer'
                  ]"
                >
                  <AppIcon
                    name="arrow-close"
                    size="26"
                    :class="collapsed ? 'text-primary' : 'text-white'"
                  />
                </div>
              </div>
            </template>

            <!-- C O N T E N T -->
            <template #content>
              <div class="md:pl-65">
                <div class="border-b border-grey-fp-10 px-10 sm:px-0 py-10">
                  <TaskRow
                    v-for="task of goal.tasks"
                    :key="task.id"
                    :task="task"
                    :disabled="disabledTasks"
                    grid-cols-width-config="50% 50%"
                    disable-date
                    @update-status="(res) => taskStatusUpdate(index, res)"
                  />
                </div>
              </div>
            </template>
          </AppCollapseItem>
        </AppCollapse>

        <AppButton
          v-if="goalsCount > goalsList.length"
          type="primary"
          class="mr-10 px-20 border-primary"
          plain
          @click="$emit('view-more')"
        >
          View more
        </AppButton>
      </div>
    </div>
  </div>
  <GoalsBanner v-if="goalsCount < 3 && !disabledBanner" class="mt-10" />
</template>

<script lang="ts">
  import { defineComponent, PropType, reactive, toRefs, onMounted, ref, watch, nextTick } from 'vue';
  import { useToast } from "vue-toastification";

  import AppCollapseItem from '@/components/stateless/AppCollapseItem.vue';
  import AppCollapse from '@/components/stateless/AppCollapse.vue';
  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import GoalsBanner from '@/views/home/components/goals/GoalsBanner.vue';
  import TaskRow from '@/components/TaskRow.vue';
  import GoalItem from '@/views/home/components/goals/GoalItem.vue';
  import AppTooltip from '@/components/stateless/AppTooltip.vue';

  import { IGoal, ITaskRes } from '@/types';
  import { myGoalsService } from '@/services';
  import { vuex } from '@/store';

  export default defineComponent({
    name: 'GoalsList',

    components: {
      AppCollapseItem,
      AppCollapse,
      GoalItem,
      AppIcon,
      TaskRow,
      AppButton,
      GoalsBanner,
      AppTooltip
    },

    props: {
      goals: {
        type: Array as PropType<IGoal[]>,
        required: true
      },

      goalsCount: {
        type: Number,
        required: true
      },

      disabledBanner: {
        type: Boolean,
        defautl: false
      },

      disabledTasks: {
        type: Boolean,
        defautl: false
      }
    },

    emits: ['view-more'],

    setup(props) {
      let goalsListContainer = ref(null) as any;
      const toast = useToast();
      const state = reactive({
        openedGoals: [] as string[],
        goalsList: [] as IGoal[]
      });

      const { openedGoals, goalsList } = toRefs(state);

      watch(() => props.goals.length, () => { 
        setLocalGoals();

        nextTick(() => {
          scrollToTop();
        });
      });

      function scrollToTop () {
        goalsListContainer.value.scrollTop = 0;
      }

      function openFirstTask () {
        if (!props.goals.length) return;

        openedGoals.value = goalsList.value[0].tasks?.length ? [goalsList.value[0].id.toString()] : [];
      }

      // Keep goal locally to update goal view without making response for a goal
      function setLocalGoals () { goalsList.value = props.goals; }

      function changeLocalTaskStatus (goalIndex: number, res: ITaskRes) {
        const tasks = goalsList.value[goalIndex].tasks;
        if (!tasks) return;

        goalsList.value[goalIndex].tasks = tasks.map((el: ITaskRes) => el.id === res.id ? res : el);
      }

      async function handleGoalPriority(goal: IGoal) {
        vuex.setLoading(true);

        return (goal.markedWithStarAt 
          ? myGoalsService.removeStarMarkGoal(goal.id) 
          : myGoalsService.markWithStarGoal(goal.id))
          .then((res: IGoal) => { 
            goalsList.value = goalsList.value.map((g: IGoal) => {
              if (g.id === goal.id) {
                g.markedWithStarAt = res.markedWithStarAt;
              }

              return g;
            });
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function taskStatusUpdate (goalIndex: number, { id, status }: ITaskRes) {
        vuex.setLoading(true);

        await myGoalsService.updateTaskStatus(status, id)
          .then((res: ITaskRes) => { changeLocalTaskStatus(goalIndex, res); })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      onMounted(() => {
        setLocalGoals();
        openFirstTask();
      });

      return {
        openedGoals,
        goalsList,
        goalsListContainer,
        handleGoalPriority,

        taskStatusUpdate
      };
    }
  });
</script>

<style lang="scss" scoped>
  .toggle-icon {
    @apply transition duration-300;
  }

  .collapse-wrapper {
    @apply relative;

    &::after {
      content: "";
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) -31%,
        #ffffff
      );
      @apply h-20 opacity-90 absolute bottom-0 left-0 right-6 sm:right-12 md:right-14 z-1;
    }

    &::before {
      content: "";
      transform: rotate(-180deg);
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) -31%,
        #ffffff
      );
      @apply h-20 opacity-90 absolute top-0 right-6 sm:right-12 md:right-14 left-0 z-1;
    }
  }

  @media (min-width: 560px) {
    .star-icon {
      display: inline;
    }
  }
</style>