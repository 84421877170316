
  import { defineComponent, inject, computed, Ref } from 'vue';

  import AppIcon from '@/components/stateless/AppIcon.vue';

  export default defineComponent({
    name: 'AppCollapseItem',

    components: { AppIcon },

    props: {

      name: {
        type: String,
        required: true
      },

      toggleOnHeader: {
        type: Boolean,
        default: false
      },

      disabled: {
        type: Boolean,
        default: false
      }
    },

    setup(props) {

      const activeNames = inject('activeNames') as Ref<string[]>;

      const handleChangeCollapseItem = inject('change') as (value: string[]) => void;

      const collapsed = computed<boolean>(() => !activeNames.value.includes(props.name));

      function toggleCollapse () {
        if (props.disabled) {
          return;
        }

        if (activeNames.value.includes(props.name)) {
          const filteredActiveNames = activeNames.value.filter((el: string) => el !== props.name);
          handleChangeCollapseItem(filteredActiveNames);
        } else {
          handleChangeCollapseItem([...activeNames.value, props.name]);
        }
      }

      return { collapsed, toggleCollapse };
    }
  });
