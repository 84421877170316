<template>
  <div class="flex justify-start gap-15">
    <!-- C A T E G O R Y   I C O N -->
    <div 
      class="h-50 min-w-50 flex justify-center self-start items-center rounded-5"
      :class="active ? 'bg-placebo-blue' : 'bg-grey-fp-10'"
    >
      <AppIcon 
        :name="goal.category ? goal.category.iconName : 'other'" 
        size="30" 
        :class="active ? 'text-primary' : 'text-posey-blue'"
      />
    </div>

    <div class="goal-summary-section flex flex-col w-full">
      <!-- G O A L  S U M M A R Y -->
      <div class="flex items-center w-full border-b border-grey-fp-10 pb-20 pr-5">
        <div class="title-section flex flex-col w-full">
          <div class="flex items-center w-full">
            <div class="mb-5 truncate">
              <span 
                class="text-md cursor-pointer"
                :class="active ? 'text-primary' : 'text-grey-fp-80'"
                @click="onGoalTitleClick"
              >
                {{ goal.title }}
              </span>
            </div>

            <AppTooltip :disabled="!!goal.markedWithStarAt" class="ml-auto star-icon">
              <AppIcon
                name="star"
                size="24"
                :class="[
                  !goal.markedWithStarAt 
                    ? 'text-posey-blue' 
                    : 'text-liquid-gold',
                  'cursor-pointer'
                ]"
                @click="$emit('prioritize')"
              />
              <template #content>
                <span class="bg-transparent">Move to top</span>
              </template>
            </AppTooltip>
          </div>

          <div 
            v-if="goal.tasks && goal.tasks.length" 
            class="flex items-center"
          >
            <div>
              <AppBadge
                icon-name="clipboard-check"
                :icon-size="10"
                :custom-classes="[active ? 'bg-placebo-blue' : 'bg-grey-fp-10', 'py-2 inline-flex']"
                :content="`${goal.tasks.length} tasks`"
                class=" mr-10 text-sm"
                :class="active ? 'text-primary' : 'text-posey-blue'"
              />
            </div>
            <div class="w-full">
              <div class="mb-2 leading-sm">
                <span class="text-grey-fp-50 text-xs">
                  Tasks Done <strong>{{ completedTasksLength }}</strong>/{{ goal.tasks.length }}
                </span>
              </div>
              <!-- P R O G R E S S  L I N E -->
              <div class="max-w-200">
                <AppProgress 
                  :value="Math.floor(completedTasksLength * 100 / goal.tasks.length)" 
                  :custom-line-class="[active ? 'bg-primary' : 'bg-posey-blue', 'h-6']"
                />
              </div>
            </div>
          </div>
          <p
            v-else
            class="text-grey-fp-50 italic"
          >
            No tasks
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType, computed, toRefs } from 'vue';

  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppBadge from '@/components/stateless/AppBadge.vue';
  import AppProgress from '@/components/stateless/AppProgress.vue';
  import AppTooltip from '@/components/stateless/AppTooltip.vue';

  import { IGoal, ITaskRes } from '@/types';
  import { router, routesNames } from '@/router';

  export default defineComponent({
    name: 'AppCollapseItem',

    components: { AppIcon, AppBadge, AppProgress, AppTooltip },


    props: {
      goal: {
        type: Object as PropType<IGoal>,
        required: true
      },

      active: {
        type: Boolean,
        required: true
      }
    },

    emits: ['prioritize'],

    setup(props) {
      const { goal } = toRefs(props);

      const completedTasksLength = computed<number>(() => {
        if (goal.value.tasks) {
          return goal.value.tasks.filter((el: ITaskRes) => el.status === 'completed').length;
        }
        return 0;
      });

      function onGoalTitleClick () {
        router.push({ name: routesNames.myGoal , query: { id: props.goal.id }});
      }

      return { completedTasksLength, onGoalTitleClick };
    }
  });
</script>

<style lang="scss" scoped>
.title-section {
  width: calc(100% - 65px);
}

.goal-summary-section {
  @media (max-width: 559px) {
    .star-icon {
      display: inline;
    }
    width: calc(100% - 40px);
  }

  @media (min-width: 560px) {
    .star-icon {
      display: none;
    }
    width: calc(100% - 65px);
  }
  
}
</style>