<template>
  <slot />
</template>

<script lang="ts">
  import { defineComponent, PropType, provide, toRefs, readonly } from 'vue';

  export default defineComponent({
    name: 'AppCollapse',

    props: {
      modelValue: {
        type: Array as PropType<string[]>,
        default: () => []
      }
    },

    emits: ['update:modelValue'],

    setup(props, { emit }) {
      const { modelValue } = toRefs(props);

      function handleChangeCollapseItem (value: string[]) { 
        emit('update:modelValue', value); 
      }

      provide('activeNames', readonly(modelValue));
      provide('change', handleChangeCollapseItem);
    }
  });
</script>
