
  import { defineComponent, PropType, computed, ref } from 'vue';
  import { useScreen } from 'vue-screen';

  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppBadge from '@/components/stateless/AppBadge.vue';
  import AppTruncatedTooltip from '@/components/stateless/AppTruncatedTooltip.vue';

  import { ITaskRes } from '@/types';

  const defautlColsWidth = '30% 45% 16% 5%';

  export default defineComponent({
    name: 'TasksRow',

    components: { AppIcon, AppBadge, AppTruncatedTooltip },

    props: {
      task: {
        type: Object as PropType<ITaskRes>,
        required: true
      },

      disabled: {
        type: Boolean,
        default: false
      },

      disableDate: {
        type: Boolean,
        default: false
      },

      disableDescription: {
        type: Boolean,
        default: false
      },

      gridColsWidthConfig: {
        type: String,
        default: ''
      },

      showActions: {
        type: Boolean,
        default: false
      }
    },

    emits: ['updateStatus', 'delete', 'update'],

    setup(props, { emit }) {
      const screen = useScreen();
      const showMore = ref<boolean>(false);

      const isShowMoreVisible = computed<boolean>(() => {
        if (screen.width < 769 && props.task.description) {
          return props.task.description?.length > 100;
        } 

        return false;
      });

      const isCompleted = computed<boolean>(() => props.task.status === 'completed');

      const gridColsWidth = computed<string>(() => props.gridColsWidthConfig || defautlColsWidth);

      const description = computed<string>(() => {
        if (props.task.description) {
          if (screen.width < 769 && props.task.description?.length > 100) {
            return showMore.value ? props.task.description : `${props.task.description.slice(0, 100)}…` ;
          } 

          return props.task.description;
        }

        return '-';
      });

      function handleShowMore () { showMore.value = !showMore.value;}

      function updateTaskStatus (id: number) { 
        if (props.disabled) { return; }

        const status = isCompleted.value ? 'todo' : 'complete';
        emit('updateStatus', { status, id }); 
      }

      return {
        isCompleted,
        showMore,

        description,
        isShowMoreVisible,
        gridColsWidth,

        handleShowMore,
        updateTaskStatus
      };
    }

  });
