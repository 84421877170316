<template>
  <div>
    <div class="relative">
      <!-- H E A D E R -->
      <slot name="header" :collapsed="collapsed" :toggleCollapse="toggleCollapse" />

      <!-- T O G G L E   C O L L A P S E   B U T T O N  -->
      <div class="absolute right-5 top-0" @click="toggleCollapse">
        <slot name="triggerIcon" :collapsed="collapsed" />
      </div>

      <AppIcon 
        v-if="!$slots.triggerIcon" 
        name="arrow-close" 
        size="26" 
        class="toggle-icon text-primary transform absolute right-0 top-0 cursor-pointer"
        :class="collapsed ? 'rotate-180' : 'rotate-0'"
        @click="toggleCollapse"
      />
    </div>

    <!-- C O N T E N T -->
    <div class="overflow-hidden transition duration-300" :class="collapsed ? 'max-h-0 ' : 'max-h-auto'">
      <slot name="content" />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, inject, computed, Ref } from 'vue';

  import AppIcon from '@/components/stateless/AppIcon.vue';

  export default defineComponent({
    name: 'AppCollapseItem',

    components: { AppIcon },

    props: {

      name: {
        type: String,
        required: true
      },

      toggleOnHeader: {
        type: Boolean,
        default: false
      },

      disabled: {
        type: Boolean,
        default: false
      }
    },

    setup(props) {

      const activeNames = inject('activeNames') as Ref<string[]>;

      const handleChangeCollapseItem = inject('change') as (value: string[]) => void;

      const collapsed = computed<boolean>(() => !activeNames.value.includes(props.name));

      function toggleCollapse () {
        if (props.disabled) {
          return;
        }

        if (activeNames.value.includes(props.name)) {
          const filteredActiveNames = activeNames.value.filter((el: string) => el !== props.name);
          handleChangeCollapseItem(filteredActiveNames);
        } else {
          handleChangeCollapseItem([...activeNames.value, props.name]);
        }
      }

      return { collapsed, toggleCollapse };
    }
  });
</script>

<style lang="scss" scoped>
.toggle-icon {
  @apply transition duration-300;
}
</style>