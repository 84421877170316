<template>
  <div class="flex flex-col items-start sm:items-center sm:flex-row bg-orange-fp-20 p-14 sm:p-20 rounded-5">
    <div class="flex items-center sm:pr-14 mb-14 sm:mb-0">
      <AppIcon name="notifications" size="20" class="text-lucky-orange mr-10" />
      <p class="text-lucky-orange">We Recommend 3 Goals:</p>
    </div>
    <AppButton 
      type="secondary" 
      icon="plus" 
      size="large"
      icon-size="15"
      icon-classes="h-30 w-30"
      custom-class="h-30"
      @click="router.push({ name: routesNames.addGoal })"
    >
      <span class="text-md">
        Add Goals
      </span>
    </AppButton>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import AppButton from '@/components/stateless/AppButton.vue';
import AppIcon from '@/components/stateless/AppIcon.vue';

import { routesNames, router } from '@/router';

export default defineComponent({
  name: 'GoalsBanner',

  components: { AppButton, AppIcon },

  setup () {
    return { routesNames, router };
  }
});
</script>