<template>
  <div class="flex w-full rounded-10 bg-placebo-blue">
    <span 
      :class="customLineClass" 
      :style="progressStyle" 
      class="inline-block rounded-10 max-w-full transition-all ease-in-out duration-200" 
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'AppProgress',

  props: {
    customLineClass: {
      type: [String, Array, Object],
      required: true
    },
    
    value: {
      type: [String, Number],
      required: true
    }
  },

  setup(props) {
    const progressStyle = computed<string>(() => `width:${props.value}%`);

    return { progressStyle };
  }
});
</script>