<template>
  <div 
    class="flex flex-col md:grid gap-10 py-10"
    :style="{gridTemplateColumns: gridColsWidth}"
  >
    <!-- T I T L E  C O L U M N -->
    <div class="flex order-0">
      <!-- E D I T  G O A L  B U T T O N -->
      <div class="flex gap-15 items-start ">
        <div 
          class="flex items-center justify-center min-h-20 min-w-20 rounded-full border"
          :class="[
            isCompleted ? 'border-success bg-success' : 'border-grey-fp-30', 
            disabled ? 'opacity-20' : 'cursor-pointer'
          ]"
          @click="updateTaskStatus(task.id)"
        >
          <AppIcon
            v-if="isCompleted"
            name="checkmark"
            size="18"
            class="text-white"
          />
        </div>
        <AppTruncatedTooltip 
          class="text-dark-cl-20" 
          :content="task.title" 
          :class="isCompleted ? 'text-success' : 'text-grey-fp-80'"
        />
      </div>
      <div 
        v-if="showActions && !disabled"
        class="flex items-center ml-auto md:hidden" 
      >
        <div class="flex">
          <AppIcon name="pen" size="18" class="text-primary cursor-pointer" @click="$emit('update', task.id)" />
          <AppIcon 
            name="trash-alt" 
            size="18" 
            class="text-error ml-15 cursor-pointer" 
            @click="$emit('delete', task.id)" 
          />
        </div>
      </div>
    </div>

    <!-- D E S C R I P T I O N  C O L U M N -->
    <div
      v-if="!disableDescription"
      class="md:flex md:justify-center md:order-1 order-2"
    >
      <p class="text-grey-fp-50">{{ description }}</p>
      <p
        v-if="isShowMoreVisible" 
        class="text-right text-primary mt-5 pr-8"
        @click="handleShowMore"
      >
        {{ `Show ${showMore ? 'less' : 'more'}` }}
      </p>
    </div>

    <!-- D A T E  C O L U M N -->
    <div 
      v-if="!disableDate"
      class="flex items-center md:justify-center md:order-2 order-1" 
      :class="{'opacity-20': disabled}"
    >
      <AppBadge 
        v-if="task.targetDate"
        icon-name="calendar" 
        :icon-size="10"
        :content="`Target Date: ${$filters.date(task.targetDate, 'MM/YYYY')}`" 
        class="text-xs text-lucky-orange"
        custom-classes="inline-flex"
      />
      <p v-else class="text-center text-grey-fp-80">-</p>
    </div>

    <!-- A C T I O N C O L U M N -->
    <div 
      v-if="showActions && !disabled"
      class="items-center md:justify-center order-3 hidden md:flex" 
    >
      <div class="flex">
        <AppIcon name="pen" size="18" class="text-primary cursor-pointer ml-15" @click="$emit('update', task.id)" />
        <AppIcon 
          name="trash-alt" 
          size="18" 
          class="text-error ml-15 cursor-pointer" 
          @click="$emit('delete', task.id)" 
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType, computed, ref } from 'vue';
  import { useScreen } from 'vue-screen';

  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppBadge from '@/components/stateless/AppBadge.vue';
  import AppTruncatedTooltip from '@/components/stateless/AppTruncatedTooltip.vue';

  import { ITaskRes } from '@/types';

  const defautlColsWidth = '30% 45% 16% 5%';

  export default defineComponent({
    name: 'TasksRow',

    components: { AppIcon, AppBadge, AppTruncatedTooltip },

    props: {
      task: {
        type: Object as PropType<ITaskRes>,
        required: true
      },

      disabled: {
        type: Boolean,
        default: false
      },

      disableDate: {
        type: Boolean,
        default: false
      },

      disableDescription: {
        type: Boolean,
        default: false
      },

      gridColsWidthConfig: {
        type: String,
        default: ''
      },

      showActions: {
        type: Boolean,
        default: false
      }
    },

    emits: ['updateStatus', 'delete', 'update'],

    setup(props, { emit }) {
      const screen = useScreen();
      const showMore = ref<boolean>(false);

      const isShowMoreVisible = computed<boolean>(() => {
        if (screen.width < 769 && props.task.description) {
          return props.task.description?.length > 100;
        } 

        return false;
      });

      const isCompleted = computed<boolean>(() => props.task.status === 'completed');

      const gridColsWidth = computed<string>(() => props.gridColsWidthConfig || defautlColsWidth);

      const description = computed<string>(() => {
        if (props.task.description) {
          if (screen.width < 769 && props.task.description?.length > 100) {
            return showMore.value ? props.task.description : `${props.task.description.slice(0, 100)}…` ;
          } 

          return props.task.description;
        }

        return '-';
      });

      function handleShowMore () { showMore.value = !showMore.value;}

      function updateTaskStatus (id: number) { 
        if (props.disabled) { return; }

        const status = isCompleted.value ? 'todo' : 'complete';
        emit('updateStatus', { status, id }); 
      }

      return {
        isCompleted,
        showMore,

        description,
        isShowMoreVisible,
        gridColsWidth,

        handleShowMore,
        updateTaskStatus
      };
    }

  });
</script>